import React, { createContext, useState, useEffect } from 'react';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from '../../assets/config/auth-config';
import { MDBContainer, MDBCol, MDBRow, MDBBtn, MDBInput } from 'mdb-react-ui-kit';
import { Route, Redirect, Switch } from "react-router-dom";
import '../../assets/css/loginpage.css';
import AdminLayout from "../../layouts/Admin";
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { msalInstance } from '../../assets/config/auth-config';
import { getUserDetails } from '../../assets/config/graph';
import axios from 'axios';

export const AuthContext = createContext();

const query = async (email) => {
  try {
    const response = await axios.get('http://localhost:5000/count_user_by_mail?email=' + email);
    return response.data.count;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const WrappedView = () => {
  const { instance, accounts } = useMsal();
  const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('userDetails')) || null);
  const [userProfile, setUserProfile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(JSON.parse(localStorage.getItem('isAdmin')) || false);

  const handleRedirect = () => {
    instance.loginRedirect({
      ...loginRequest,
      prompt: 'create',
    }).catch((error) => console.log(error));
  };

  const handleLogout = () => {
    instance.logoutPopup({
      mainWindowRedirectUri: "/"
    }).catch((error) => console.log(error));
    localStorage.removeItem('userDetails');
    localStorage.removeItem('isAdmin');
    setUserDetails(null);
    setIsAdmin(false);
  };

  useEffect(() => {
    if (accounts.length > 0 && !userDetails) {
      const fetchData = async () => {
        try {
          const userDetailsResponse = await getUserDetails(instance, accounts);
          setUserDetails(userDetailsResponse);
          localStorage.setItem('userDetails', JSON.stringify(userDetailsResponse));
          setLoading(false);
        } catch (error) {
          console.error("Error fetching user details: ", error);
          setError(error.message);
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [instance, accounts, userDetails]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

  useEffect(() => {
    document.body.classList.add('login-page');
    return () => {
      document.body.classList.remove('login-page');
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const welcomeStyle = {
    fontFamily: "'Century Gothic', sans-serif",
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#113c60',
    marginBottom: '10px',
  };

  useEffect(() => {
    if (userDetails) {
      query(userDetails.mail)
        .then(result => {
          setIsAdmin(result != '0');
          localStorage.setItem('isAdmin', JSON.stringify(result != '0'));
        })
        .catch(error => console.error('Error in query:', error));
    }
  }, [userDetails]);

  return (
    <AuthContext.Provider value={{ userDetails, handleLogout }}>
      <div className="App">
        <AuthenticatedTemplate>
          <Switch>
            {isAdmin ? (
              <>
                <Route path="/admin/accueil" render={(props) => <AdminLayout {...props} />} />
                <Redirect from="/" to="/admin/accueil" />
              </>
            ) : (
              <>
                <Route path="/admin/user-profile" render={(props) => <AdminLayout {...props} />} />
                <Redirect from="/" to="/admin/user-profile" />
              </>
            )}
          </Switch>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <MDBContainer fluid className={`d-flex justify-content-center align-items-center vh-100 ${isMobile ? 'mobile-background' : ''}`}>
            <MDBRow className="justify-content-center align-items-center">
              <MDBCol col='8' md='12' className="text-center">
                <div className="d-flex justify-content-center mb-4">
                  <a href='https://www.aeim.eu'>
                    <img src={require('../../assets/img/aeim_logo.png')} className="img-fluid-center" style={{ maxWidth: '75%', height: 'auto' }} alt="Logo" />
                  </a>
                </div>
                <div className="d-flex justify-content-center">
                  <h1 style={welcomeStyle}>Bienvenue</h1>
                </div>
                <MDBInput disabled wrapperClass='mb-4' label='Email' id='formControlLg' type='email' size="lg" />
                <MDBInput disabled wrapperClass='mb-1' label='Mot de passe' id='formControlLg' type='password' size="lg" />
                <div className="d-flex justify-content-end mb-3">
                  <a href="!#" className="text-decoration-none">Mot de passe oublié ?</a>
                </div>
                <MDBBtn className="mb-3 w-100" size="lg" onClick={''}>Se connecter</MDBBtn>
                <div className="divider d-flex align-items-center mb-3">
                  <p className="text-center fw-bold mx-3 mb-0">OU</p>
                </div>
                <MDBBtn
                  className="mb-3 w-100"
                  size="lg"
                  style={{ backgroundColor: '#ffffff', color: '#000000', border: '1px solid #000000', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}
                  onClick={handleRedirect}
                >
                  <img src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg" alt="Microsoft logo" style={{ height: '20px', marginRight: '10px' }} />
                  Continuer avec Microsoft
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </UnauthenticatedTemplate>
      </div>
    </AuthContext.Provider>
  );
}

const LoginPage = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <WrappedView />
    </MsalProvider>
  );
};

export default LoginPage;
